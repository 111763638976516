
import * as actions from '../action/coupon.actions';
// state
import { CouponState, CouponStateRecord } from './coupon.state';
// model
import { MasterCouponListResponseModel } from '../models/master-coupon-List.response.model';
import { MasterCouponAddresponseModel } from '../models/master-coupon-Add-Response.model';

export const initialState: CouponState = new CouponStateRecord() as CouponState;

export function reducer(
  state = initialState,
  { type, payload }: any
): CouponState {
  if (!type) {
    return state;
  }
  switch (type) {
    // Manufacturer list
    case actions.ActionTypes.DO_MASTERCOUPON_LIST_ACTION: {
      return Object.assign({}, state, {
        listLoading: true,
        listLoaded: false,
        listFailed: false
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_LIST_SUCCESS: {
      let ManufacturerModel = {};
      if (payload.data) {
        ManufacturerModel = payload.data.map(_brand => {
          const tempmanufacturerModel = new MasterCouponListResponseModel(
            _brand
          );
          return tempmanufacturerModel;
        });
      }
      return Object.assign({}, state, {
        listLoading: false,
        listLoaded: true,
        listFailed: false,
        masterCouponList: ManufacturerModel
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_LIST_FAIL: {
      return Object.assign({}, initialState, {
        listLoading: false,
        listLoaded: true,
        listFailed: true
      });
    }
    // Manufacturer add
    case actions.ActionTypes.DO_MASTERCOUPON_ADD_ACTION: {
      return Object.assign({}, state, {
        addLoading: true,
        addLoaded: false,
        addFailed: false
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_ADD_SUCCESS: {
      const couponData = new MasterCouponAddresponseModel(payload.data);
      return Object.assign({}, state, {
        masterCouponAdd: couponData,
        addLoading: false,
        addLoaded: true,
        addFailed: false
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_ADD_FAIL: {
      return Object.assign({}, initialState, {
        addLoading: false,
        addLoaded: false,
        addFailed: true
      });
    }

    // Manufacturer update
    case actions.ActionTypes.DO_MASTERCOUPON_UPDATE_ACTION: {
      return Object.assign({}, state, {
        updateLoading: true,
        updateLoaded: false,
        updateFailed: false
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_UPDATE_FAIL: {
      return Object.assign({}, initialState, {
        updateLoading: false,
        updateLoaded: false,
        updateFailed: true
      });
    }

    case actions.ActionTypes.DO_MASTERCOUPON_UPDATE_SUCCESS: {
      return Object.assign({}, state, {
        updateLoading: false,
        updateLoaded: true,
        updateFailed: false,
        masterCouponUpdate: payload
      });
    }
    // Manufacturer delete
    case actions.ActionTypes.DO_MASTERCOUPON_DELETE_ACTION: {
      return Object.assign({}, state, {
        deleteLoading: true,
        deleteLoaded: false,
        deleteFailed: false
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_DELETE_SUCCESS: {
      return Object.assign({}, state, {
        deleteLoading: false,
        deleteLoaded: true,
        deleteFailed: false,
        masterCouponDelete: payload
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_DELETE_FAIL: {
      return Object.assign({}, initialState, {
        deleteLoading: false,
        deleteLoaded: false,
        deleteFailed: true
      });
    }
    // Manufacturer count
    case actions.ActionTypes.DO_MASTERCOUPON_COUNT_ACTION: {
      return Object.assign({}, state, {
        countLoading: true,
        countLoaded: false,
        countFailed: false
      });
    }
    case actions.ActionTypes.DO_MASTERCOUPON_COUNT_SUCCESS: {
      return Object.assign({}, state, {
        countLoading: false,
        countLoaded: true,
        countFailed: false,
        masterCouponCount: payload.data
      });
    }

    case actions.ActionTypes.DO_MASTERCOUPON_COUNT_FAIL: {
      return Object.assign({}, initialState, {
        countLoading: false,
        countLoaded: true,
        countFailed: true
      });
    }

    default: {
      return state;
    }
  }
}

// Manufacturer add
export const getCouponAdd = (state: CouponState) => state.masterCouponAdd;
export const getCouponAddLoading = (state: CouponState) =>
  state.addLoading;
export const getCouponAddLoaded = (state: CouponState) => state.addLoaded;
export const getCouponAddFailed = (state: CouponState) => state.addFailed;

// Manufacturer update
export const getCouponUpdate = (state: CouponState) =>
  state.masterCouponUpdate;
export const getCouponUpdateLoading = (state: CouponState) =>
  state.updateLoading;
export const getCouponUpdateLoaded = (state: CouponState) =>
  state.updateLoaded;
export const getCouponUpdateFailed = (state: CouponState) =>
  state.updateFailed;

// Manufacturer list
export const getCouponList = (state: CouponState) =>
  state.masterCouponList;
export const getCouponListLoading = (state: CouponState) =>
  state.listLoading;
export const getCouponListLoaded = (state: CouponState) => state.listLoaded;
export const getCouponListFailed = (state: CouponState) =>
  state.listFailed;

// Manufacturer count
export const getCouponCount = (state: CouponState) =>
  state.masterCouponCount;
export const getCouponCountLoading = (state: CouponState) =>
  state.countLoading;
export const getCouponCountLoaded = (state: CouponState) =>
  state.countLoaded;
export const getCouponCountFailed = (state: CouponState) =>
  state.countFailed;

// Manufacturer delete
export const getCouponDelete = (state: CouponState) =>
  state.masterCouponDelete;
export const getCouponDeleteLoading = (state: CouponState) =>
  state.deleteLoading;
export const getCouponDeleteLoaded = (state: CouponState) =>
  state.deleteLoaded;
export const getCouponDeleteFailed = (state: CouponState) =>
  state.deleteFailed;
