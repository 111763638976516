

export class MasterCouponAddresponseModel {
    public couponId: number;
    public couponCode: string;
    public couponType: string;
    public discountType: string;
    public discountValue: number;
    public expDate:string;
    public maxRedemption: number;
    public status: number;
    public operationId: number;

  constructor(mastercouponAddresponse: any) {
    this.couponCode = mastercouponAddresponse.couponCode || '';
    this.couponId = mastercouponAddresponse.couponId || 0;
    this.couponType = mastercouponAddresponse.couponType || '';
    this.discountType = mastercouponAddresponse.discountType || '';
    this.discountValue = mastercouponAddresponse.discountValue || 0;
    this.expDate = mastercouponAddresponse.expDate || '';
    this.maxRedemption = mastercouponAddresponse.maxRedemption || 0;
    this.status = mastercouponAddresponse.status || 0;
    this.operationId = mastercouponAddresponse.operationId || 0;
  }
}
