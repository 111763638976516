
export class MasterCouponListResponseModel {
  public couponId: number;
  public couponCode: string;
  public couponType: string;
  public discountType: string;
  public discountValue: number;
  public expDate:string;
  public maxRedemption: number;
  public operationId: number;
  public usedCoupon: number;
  public status: number;

  constructor(mastercouponListResponse: any) {
    this.couponCode = mastercouponListResponse.couponCode || '';
    this.couponId = mastercouponListResponse.couponId || 0;
    this.couponType = mastercouponListResponse.couponType || '';
    this.discountType = mastercouponListResponse.discountType || '';
    this.discountValue = mastercouponListResponse.discountValue || 0;
    this.expDate = mastercouponListResponse.expDate || '';
    this.maxRedemption = mastercouponListResponse.maxRedemption || 0;
    this.operationId = mastercouponListResponse.operationId || 0;
    this.usedCoupon = mastercouponListResponse.usedCoupon || 0;
    this.status = mastercouponListResponse.status;
  }
}
